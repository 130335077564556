import store from '../store';

class User {
  get permissions() {
    return store.getters.permissions;
  }

  get role() {
    return store.getters.user_role;
  }

  can = (permission) => {
    return this.permissions.includes(permission);
  };

  cant = (permission) => {
    return !this.can(permission);
  };
}

export default new User();
