import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import router from './router'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

let timer = null
const diff_minutes = (dt2, dt1) => {
  var diff = (dt2.getTime() - dt1.getTime()) / 1000
  diff /= 60
  return Math.abs(Math.round(diff))
}

export default new Vuex.Store({
  state: {
    accessToken: null,
    loggingIn: false,
    loginError: null,
    userRole: null,
    userName: null,
    timestamp: null,
    permissions: [],
  },
  mutations: {
    loginStart: (state) => (state.loggingIn = true),
    loginStop: (state, errorMessage) => {
      state.loggingIn = false
      state.loginError = errorMessage
    },
    updateAccessToken: (state, accessToken) => {
      state.accessToken = accessToken
    },
    updateUserRole: (state, userRole) => {
      state.userRole = userRole
    },
    updateUserName: (state, userName) => {
      state.userName = userName
    },
    updatePermissions: (state, permissions) => {
      state.permissions = permissions
    },
    logout: (state) => {
      state.accessToken = null
      state.userRole = null

      localStorage.clear()
    },
    updateAuthTimestamp: (state, timestamp) => {
      state.timestamp = timestamp
      localStorage.setItem('authTimestamp', timestamp)
    },
  },
  actions: {
    doLogin({ commit }, loginData) {
      commit('loginStart')
      localStorage.clear()

      return axios({
        url: process.env.VUE_APP_API_URL + '/api/cms/auth/login',
        data: {
          username: loginData.email,
          password: loginData.password,
        },
        method: 'POST',
      })
        .then((response) => {
          const timestamp = new Date()
          localStorage.setItem('accessToken', response.data.access_token)
          commit('loginStop', null)
          commit('updateAccessToken', response.data.access_token)
          commit('updateUserName', response.data.name)
          commit('updateAuthTimestamp', timestamp)

          router.push({ name: 'dashboard' })

          return response
        })
        .catch((error) => {
          const message =
            error.response.status === 400
              ? 'Invalid username and/or password.'
              : error.response.data.error_description

          commit('loginStop', message)
          commit('updateAccessToken', null)

          throw error
        })
    },
    fetchAccessToken({ commit, dispatch }) {
      commit('updateAccessToken', localStorage.getItem('accessToken'))
      commit('updateAuthTimestamp', localStorage.getItem('authTimestamp'))
      dispatch('checkActiveSession')
      dispatch('addEventListeners')
    },
    logout({ commit, dispatch }) {
      commit('logout')
      dispatch('removeEventListeners')
      router.push('/login')
      localStorage.clear()
    },
    checkActiveSession({ dispatch, state }) {
      if (timer) clearTimeout(timer)

      timer = setTimeout(() => {
        const now = new Date()
        const end = new Date(state.timestamp)

        const difference = diff_minutes(now, end)

        if (difference > 30) {
          dispatch('logout')
        } else {
          dispatch('checkActiveSession')
        }
      }, 60 * 1000)
    },
    handleApiResponse({ commit, dispatch }, request) {
      if (request && request.status === 401) {
        commit('logout')
        dispatch('removeEventListeners')
        router.push('/login')
        localStorage.clear()
      } else if (request && request.status === 422) {
        let message = 'Er is iets misgegaan...:\n'

        for (const [key, value] of Object.entries(request.data.errors)) {
          if (key && value) message += '- ' + value.toString() + '\n'
        }

        alert(message)
      } else if (request && request.status === 500) {
        let message = 'Er is iets misgegaan...:\n'

        if (request && request.data) {
          for (const [key, value] of Object.entries(request.data.errors)) {
            if (key && value) message += '- ' + value.toString() + '\n'
          }
        }

        alert(message)
      }
    },
    addEventListeners({ commit }) {
      window.addEventListener(
        'mousemove',
        () => {
          commit('updateAuthTimestamp', new Date())
        },
        false
      )

      window.addEventListener(
        'keydown',
        () => {
          commit('updateAuthTimestamp', new Date())
        },
        false
      )
    },
    removeEventListeners({ commit }) {
      window.removeEventListener(
        'mousemove',
        () => {
          commit('updateAuthTimestamp', new Date())
        },
        false
      )

      window.removeEventListener(
        'keydown',
        () => {
          commit('updateAuthTimestamp', new Date())
        },
        false
      )
    },
  },
  getters: {
    userRole: (state) => {
      return state.userRole
    },
    userName: (state) => {
      return state.userName
    },
    permissions: (state) => {
      return state.permissions
    },
  },
  plugins: [createPersistedState({ storage: localStorage })],
})
