import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

// Containers
const DefaultContainer = (resolve) =>
  require(['@/containers/DefaultContainer'], resolve)

// 404
const Page404 = (resolve) => require(['@/views/pages/Page404'], resolve)

// Projects
const Projects = (resolve) => require(['@/views/projects/Index'], resolve)
const Project = (resolve) => require(['@/views/projects/Show'], resolve)

// Projects Updates
const ProjectUpdate = (resolve) =>
  require(['@/views/projects/updates/Show'], resolve)

// Project introductions
const ProjectIntroductions = (resolve) =>
  require(['@/views/project-introductions/Index'], resolve)
const ProjectIntroduction = (resolve) =>
  require(['@/views/project-introductions/Show'], resolve)

// Admins
const Users = (resolve) => require(['@/views/users/Index'], resolve)
const User = (resolve) => require(['@/views/users/Show'], resolve)

// Contacts
const Contacts = (resolve) => require(['@/views/contacts/Index'], resolve)
const Contact = (resolve) => require(['@/views/contacts/Show'], resolve)

// News
const News = (resolve) => require(['@/views/news/Index'], resolve)
const NewsSingle = (resolve) => require(['@/views/news/Show'], resolve)

// Frequently Asked Questions
const FAQ = (resolve) => require(['@/views/frequentlyaskedquestions/Index'], resolve)
const FAQSingle = (resolve) =>
  require(['@/views/frequentlyaskedquestions/Show'], resolve)

// Accounts
const Accounts = (resolve) => require(['@/views/accounts/Index'], resolve)
const Account = (resolve) => require(['@/views/accounts/Show'], resolve)

// Accounts
const PortalUsers = (resolve) => require(['@/views/portal-users/Index'], resolve)
const PortalUser = (resolve) => require(['@/views/portal-users/Show'], resolve)

// Groups
const Groups = (resolve) => require(['@/views/groups/Index'], resolve)
const Group = (resolve) => require(['@/views/groups/Show'], resolve)

// Profile
const Profile = (resolve) => require(['@/views/profile/Show'], resolve)

// Login
const Login = (resolve) => require(['@/views/login/Login'], resolve)
const PasswordForgot = (resolve) =>
  require(['@/views/login/PasswordForgot'], resolve)
const PasswordReset = (resolve) => require(['@/views/login/PasswordReset'], resolve)
const ActivateAccount = (resolve) =>
  require(['@/views/login/ActivateAccount'], resolve)

const Dashboard = (resolve) => require(['@/views/pages/Dashboard'], resolve)

Vue.use(Router)

const router = new Router({
  mode: 'history',
  linkActiveClass: 'open active',
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: '/admin',
      name: 'admin',
      redirect: 'admin/dashboard',
      component: DefaultContainer,
      children: [
        {
          path: 'dashboard',
          name: 'dashboard',
          component: Dashboard,
        },
        {
          path: 'profile',
          name: 'profile',
          component: Profile,
        },
        {
          path: 'projects',
          name: 'projects',
          component: Projects,
        },
        {
          path: 'projects/:id',
          name: 'projects.view',
          component: Project,
        },
        {
          path: 'projects/:id/updates/:update_id',
          name: 'projects.updates.view',
          component: ProjectUpdate,
        },
        {
          path: 'project-introductions',
          name: 'project-introductions',
          component: ProjectIntroductions,
        },
        {
          path: 'project-introductions/:id',
          name: 'project-introductions.view',
          component: ProjectIntroduction,
        },
        {
          path: 'users',
          name: 'users',
          component: Users,
        },
        {
          path: 'users/:id',
          name: 'users.view',
          component: User,
        },
        {
          path: 'news',
          name: 'news',
          component: News,
        },
        {
          path: 'news/:id',
          name: 'news.view',
          component: NewsSingle,
        },
        {
          path: 'faq',
          name: 'faq',
          component: FAQ,
        },
        {
          path: 'faq/:id',
          name: 'faq.view',
          component: FAQSingle,
        },
        {
          path: 'accounts/contacts',
          name: 'contacts',
          component: Contacts,
        },
        {
          path: 'accounts/contacts/:id',
          name: 'contacts.view',
          component: Contact,
        },
        {
          path: 'accounts/relations',
          name: 'relations',
          component: Accounts,
        },
        {
          path: 'accounts/relations/:id',
          name: 'accounts.view',
          component: Account,
        },
        {
          path: 'accounts/portal-users',
          name: 'portal-users',
          component: PortalUsers,
        },
        {
          path: 'accounts/portal-user/:id',
          name: 'portal-users.view',
          component: PortalUser,
        },
        {
          path: 'accounts/groups',
          name: 'groepen',
          component: Groups,
        },
        {
          path: 'accounts/groups/:id',
          name: 'groups.view',
          component: Group,
        },
      ],
    },
    {
      path: '/',
      name: 'login-main',
      redirect: '/login',
      component: {
        render(c) {
          return c('router-view')
        },
      },
      children: [
        {
          path: 'login',
          name: 'login',
          component: Login,
        },
        {
          path: 'forgot-password',
          name: 'forgot-password',
          component: PasswordForgot,
        },
        {
          path: 'reset-password',
          name: 'reset-password',
          component: PasswordReset,
        },
        {
          path: 'activate-account',
          name: 'activate-account',
          component: ActivateAccount,
        },
      ],
    },
    {
      path: '*',
      component: Page404,
    },
  ],
})

router.beforeEach((to, from, next) => {
  store.dispatch('fetchAccessToken')
  if (to.fullPath.includes('admin')) {
    if (!store.state.accessToken) {
      next('/login')
    }
  }
  if (to.fullPath === '/login') {
    if (store.state.accessToken) {
      next('/admin')
    }
  }
  next()
})

export default router
