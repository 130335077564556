import Vue from 'vue'
import vSelect from 'vue-select'
import Vuex from 'vuex'
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import router from './router'
import store from './store'
import User from './classes/user'

Vue.use(BootstrapVue)
Vue.use(Vuex)

Vue.component('v-select', vSelect)

function commentNode(el, vnode) {
  const comment = document.createComment(' ')

  Object.defineProperty(comment, 'setAttribute', {
    value: () => undefined,
  })

  vnode.text = ' '
  vnode.elm = comment
  vnode.isComment = true
  vnode.context = undefined
  vnode.tag = undefined
  vnode.data.directives = undefined

  if (vnode.componentInstance) {
    vnode.componentInstance.$el = comment
  }

  if (el.parentNode) {
    el.parentNode.replaceChild(comment, el)
  }
}

Vue.directive('can', function (el, binding, vnode) {
  const behaviour = binding.modifiers.disable ? 'disable' : 'hide'
  if (User.cant(binding.value)) {
    if (behaviour === 'hide') {
      commentNode(el, vnode)
    } else if (behaviour === 'disable') {
      el.disabled = true
    }
  } else {
    el.disabled = false
  }
})

/* eslint-disable no-new */
new Vue({
  el: '#app',
  router,
  template: '<App/>',
  components: {
    App,
  },
  store,
})
