<template>
  <router-view></router-view>
</template>

<script>
export default {
  name: 'app',
}
</script>

<style lang="scss">
  /* Import Font Awesome Icons Set */
  $fa-font-path: '~font-awesome/fonts/';
  @import '~font-awesome/scss/font-awesome.scss';
  /* Import Simple Line Icons Set */
  $simple-line-font-path: '~simple-line-icons/fonts/';
  @import '~simple-line-icons/scss/simple-line-icons.scss';
  /* Import Bootstrap Vue Styles */
  @import '~bootstrap-vue/dist/bootstrap-vue.css';
  /*vue select styles*/
  @import "~vue-select/src/scss/vue-select.scss";
  // Import Main styles for this application
  @import 'assets/scss/style';

  @import '~pc-bootstrap4-datetimepicker/build/css/bootstrap-datetimepicker.min.css';

  @import '~vue-multiselect/dist/vue-multiselect.min.css';
</style>
